import React, { Component } from "react";
import { API } from "aws-amplify";
import { HelpBlock, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewNote.css";

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      content: ""
    };
}

  createNote(note) {
    return API.post("notes", "/notes", {
      body: note
    });
  }

  validateForm() {
    return this.state.content.length > 0;

  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;
// Possible if statement here if there's no note text
      await this.createNote({
        attachment,
        content: this.state.content
      });


      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="NewNote">
        <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="file">
            <ControlLabel>Upload a PS/ALI data file for processing</ControlLabel>
            <p></p>
            <p></p>
            <FormControl onChange={this.handleFileChange} type="file" />
            <p></p>
            <HelpBlock>(NENA standard .DAT or Excel files only)</HelpBlock>
          </FormGroup>

        <FormGroup controlId="content" bsSize="large">
        <ControlLabel></ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              placeholder="Enter your first and last name as a digital signature"
              
            />
            <HelpBlock>Digital Signature (required)</HelpBlock>
          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Submit"
            loadingText="Uploading…"
          />
        </form>
      </div>
    );
  }
}